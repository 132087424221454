import React from 'react'
import { config } from '@fortawesome/fontawesome-svg-core'
import type { AppProps } from 'next/app'
import 'react-phone-number-input/style.css'
import 'animate.css'
import { AnimatePresence } from 'framer-motion'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

config.autoAddCss = false

const App = ({ Component, pageProps, router }: AppProps) => (
  <QueryClientProvider client={queryClient}>
    <AnimatePresence mode="wait" initial={false} onExitComplete={() => window.scrollTo(0, 0)}>
      <Component {...pageProps} key={router.asPath} />
    </AnimatePresence>
  </QueryClientProvider>
)

export default App
